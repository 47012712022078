<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data" onkeydown="return event.key != 'Enter';">
    <v-container id="FORNITORI" fluid tag="section">
      <v-row>
        <v-col :cols="isEditFornitore ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-account-group"
            :title="$t('fornitori.titoloGriglia')"
            class="px-5 py-3"
            backgroundcolor=""
            titlecolor="red"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('fornitori.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col>
                  <v-select
                    :items="t_anni"
                    :label="$t('anno')"
                    style="margin-top: 10px; width: 150px"
                    v-model="defaultAnno"
                    @change="onAnnoChange"
                  >
                  </v-select>
                </v-col>
                <v-col align="end">
                  <v-btn
                    color="darkred"
                    width="180px"
                    @click="onNuovoFornitoreClick"
                  >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    {{ $t("fornitori.nuovoContratto") }}
                  </v-btn>
                  <v-btn 
                    color="darkred" 
                    width="180px"
                    @click="onExportGrid">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblMainFornitori"
              :items="fornitori.source"
              :headers="headersColumns"
              :search="search"
              item-key="idImpegno"
              :value="[fornitoreSel]"
              @click:row="onTableRowClick"
              single-select
              class="elevation-1"
              :items-per-page="-1"
              :loading="isloading_fornitori"
              loading-text="Caricamento dati in corso..."
            >
            <template v-slot:[`item.budgetAnnuale`]="{ item }">
              <span>{{ '€' + item.budgetAnnuale.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
            <template v-slot:[`item.budgetUtilizzato`]="{ item }">
              <span>{{ '€' + item.budgetUtilizzato.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
            <template v-slot:[`item.budgetResiduo`]="{ item }">
              <span>{{ '€' + item.budgetResiduo.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
            <template v-slot:[`item.budgetSpesaMediaMensile`]="{ item }">
              <span>{{ '€' + item.budgetSpesaMediaMensile.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
            <template v-slot:[`item.budgetScostamento`]="{ item }">
              <span>{{ '€' + item.budgetScostamento.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
            </template>
            </v-data-table>
          </base-material-card>
        </v-col>
        <v-col v-if="isEditFornitore" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-account"
            :title="
              $t('page.fornitore.titolo') +
              ': ' +
              fornitoreSel.fornitore.cRagioneSociale
            "
            :subtitle="'Determina: ' + fornitoreSel.determinaAnno"
            class="px-5 py-3"
          >
            <v-tabs v-model="tab" background-color="transparent" grow>
              <v-tab
                v-for="item in fornitoriTabs"
                :key="item"
                @change="getTabSelected(item)"
              >
                {{ item }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in fornitoriTabs" :key="item" eager>
                <v-card v-if="item == 'Anagrafica Fornitore'" flat>
                  <v-card-text>
                      <v-radio-group v-if="isNuovoContratto" row
                        v-model="radios"
                        mandatory
                        :disabled="shouldDisable"
                        @change="onRadioChanged"
                      >
                        <v-radio label="Esistente" value="Esistente"></v-radio>
                        <v-radio label="Nuovo" value="Nuovo"></v-radio>
                      </v-radio-group>
                  </v-card-text>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_tipofornitore"
                        :label="$t('tipoFornitore')"
                        item-text="cDescrizione"
                        item-value="cDescrizione"
                        style="margin-top: 10px; width: 150px"
                        v-model="fornitoreSel.fornitore.tipoFornitore"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col v-if="radios == 'Nuovo'">
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cRagioneSociale"
                        :label="$t('ragioneSocialeObbl')"
                        :disabled="shouldDisable"
                        :rules="[rules.required]"
                        maxlength="80"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col v-if="radios == 'Esistente'">
                      <v-select
                        :items="eFornitori"
                        :label="$t('ragioneSocialeObbl')"
                        return-object
                        item-text="cRagioneSociale"
                        item-value="id"
                        v-model="t_fornSel"
                        :rules="[rules.selectRequired]"
                        :disabled="shouldDisable"
                        @change="onFornitoreChange"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cPartitaIva"
                        :label="$t('partitaIvaObbl')"
                        maxlength="11"
                        :disabled="shouldDisable"
                        :rules="[rules.required]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="fornitoreSel.fornitore.fornCategoria"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        :items="t_categoriaforn"
                        :label="$t('categoriaObbl')"
                        :disabled="shouldDisable"
                        :rules="[rules.selectRequired]"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cLocalita"
                        :label="$t('localita')"
                        maxlength="80"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cIndirizzo"
                        :label="$t('indirizzo')"
                        maxlength="100"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cTelefono"
                        :label="$t('telefoni')"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.fornitore.cEmail"
                        :label="$t('emailObbl')"
                        maxlength="50"
                        :disabled="shouldDisable"
                        :rules="[rules.email]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="fornitoreSel.fornitore.xNote"
                        outlined
                        name="txtNote"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Determina'" flat>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="eEnti"
                        :label="$t('ente')"
                        return-object
                        item-text="cDescrizione"
                        item-value="id"
                        v-model="fornitoreSel.enteDTO"
                        :rules="[rules.selectRequired]"
                        :disabled="enteShouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cNumero"
                        :label="$t('numeroDeterminaObbl')"
                        type="number"
                        min="1"
                        max="1000000"
                        :disabled="shouldDisable"
                        :rules="[rules.required, rules.valueMin(fornitoreSel.cNumero, 0), rules.valueMax(fornitoreSel.cNumero, 1000000)]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cDescrizione"
                        :label="$t('descrizione')"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.dataInizioText"
                        :label="$t('dataDeterminaObbl')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                        :rules="[rules.required]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.dataFineText"
                        :label="$t('dataFineObbl')"
                        type="date"
                        min="1990-01-01"
                        max="2050-12-31"
                        :disabled="shouldDisable"
                        v-show="false"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cCig"
                        :label="$t('cig')"
                        maxlength="15"
                        :rules="[rules.required]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="fornitoreSel.xNote"
                        outlined
                        name="txtNote"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("riferimentiFornitore") }}
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cReferente"
                        :label="$t('referente')"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cTelefono"
                        :label="$t('telefoni')"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cEmail"
                        :label="$t('emailObbl')"
                        maxlength="50"
                        :disabled="shouldDisable"
                        :rules="[rules.required, rules.email]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cPec"
                        :label="$t('pec')"
                        maxlength="50"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="fornitoreSel.cIban"
                        :label="$t('iban')"
                        maxlength="27"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <CompDocuments
                  v-if="item == 'Documenti'"
                  :eDocumenti="fornitoreSel.documenti"
                  :t_documenti="t_documenti"
                  :shouldDisable="shouldDisable"
                  v-on:send-messagge="showAlertMessage"
                  v-on:load-document="loadDocument"
                  v-on:delete-document="deleteDocument"
                />

                <v-card v-if="item == 'Budget'" flat>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                    >{{ $t("elencoImpegni") }}
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-data-table
                        dense
                        hide-default-footer
                        :headers="headersImpegni"
                        :items="fornitoreSel.impegni"
                        :value="[impegnoSel]"
                        single-select
                        class="elevation-1"
                        @click:row="onTableRowImpegniClick"
                      >
                        <template v-slot:[`item.nTotale`]="{ item }">
                          <span>{{ '€' + item.nTotale.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
                        </template>

                        <template v-slot:top>
                          <v-dialog
                            v-model="dialogDeleteImpegno"
                            max-width="500px"
                          >
                            <v-card>
                              <v-card-title class="text-h5"
                                >Sei sicuro di voler cancellare l'elemento
                                selezionato?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeDeleteImpegno"
                                  >Annulla</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirmImpegno"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            v-show="!shouldDisable"
                            small
                            @click="deleteItemImpegno(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4"></v-divider>
                  <v-row>
                    <v-col cols="6">
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                      >
                        {{ $t("datiImpegno") }}
                      </v-card-title>
                    </v-col>
                    <v-col style="margin-top: 10px" align="end"  cols="3">
                      <v-btn
                        tile
                        color="darkred"
                        :disabled="shouldDisable"
                        @click="onDuplicaImpegnoClick"
                      >
                        <v-icon left> mdi-content-duplicate </v-icon>
                        {{ $t("duplicaImpegno") }}
                      </v-btn>
                    </v-col>
                    <v-col style="margin-top: 10px" align="end"  cols="3">
                      <v-btn
                        tile
                        color="darkred"
                        :disabled="shouldDisable"
                        @click="onNuovoImpegnoClick"
                      >
                        <v-icon left> mdi-plus-circle-outline </v-icon>
                        {{ $t("nuovoImpegno") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="impegnoSel.nNumero"
                        :label="$t('numero')"
                        type="number"
                        min="1"
                        max="1000000"
                        :rules="[rules.valueMin(impegnoSel.nNumero, 0), rules.valueMax(impegnoSel.nNumero, 1000000)]"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="impegnoSel.nAnno"
                        :label="$t('impegni.annoObbl')"
                        type="number"
                        min="1970"
                        max="2050"
                        :rules="[rules.valueMin(impegnoSel.nAnno, 1970), rules.valueMax(impegnoSel.nAnno, 2050)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDownDecimalAndNegative"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="impegnoSel.cDescrizione"
                        :label="$t('descrizione')"
                        :disabled="shouldDisable"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-currency-field 
                        :label="$t('impegni.importoInizialeObbl')"
                        prefix="€"
                        v-model="impegnoSel.nImpIniziale"
                        :disabled="shouldDisable"
                        @change="changeTotale"/>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('assestamento1')"
                        prefix="€"
                        v-model="impegnoSel.nAssestamento1"
                        :disabled="shouldDisable"
                        @change="changeTotale"/>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('assestamento2')"
                        prefix="€"
                        v-model="impegnoSel.nAssestamento2"
                        :disabled="shouldDisable"
                        @change="changeTotale"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-currency-field 
                        :label="$t('assestamento3')"
                        prefix="€"
                        v-model="impegnoSel.nAssestamento3"
                        :disabled="shouldDisable"
                        @change="changeTotale"/>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('assestamento4')"
                        prefix="€"
                        v-model="impegnoSel.nAssestamento4"
                        :disabled="shouldDisable"
                        @change="changeTotale"/>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('totale')"
                        prefix="€"
                        v-model="impegnoSel.nTotale"
                        disabled
                        />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="eCapitoliSpesa"
                        return-object
                        item-text="numCapitoloDescrizione"
                        item-value="id"
                        :label="$t('capitoloSpesa')"
                        v-model="impegnoSel.capitoloSpesaDTO"
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-textarea
                        v-model="impegnoSel.xNote"
                        outlined
                        name="txtNoteBudget"
                        :label="$t('note')"
                        :readonly="shouldDisable"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>

                  <v-row>
                    <v-col style="margin-top: 10px" align="end">
                      <v-btn
                        tile
                        color="darkred"
                        :disabled="shouldDisable"
                        @click="onSalvaImpegnoClick"
                      >
                        <v-icon left> mdi-content-save </v-icon>
                        {{ $t("fornitori.salvaImpegno") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card v-if="item == 'Manodopera'" flat>
                  <v-card-title
                    class="black--text"
                    style="margin-bottom: 20px; margin-top: 20px"
                  >
                    {{ $t("prezziManodopera") }}
                  </v-card-title>
                  <v-row>
                    <v-col>
                      <v-data-table
                        dense
                        hide-default-footer
                        :headers="headersManodopera"
                        :items="fornitoreSel.manodopere"
                        :value="[manodoperaSel]"
                        single-select
                        item-key="guid"
                        class="elevation-1"
                        @click:row="onTableRowManodopereClick"
                      >
                        <template v-slot:[`item.nPrezzoUnitario`]="{ item }">
                          <span>{{ '€' + item.nPrezzoUnitario.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
                        </template>

                        <template v-slot:top>
                          <v-dialog
                            v-model="dialogDeleteManodopera"
                            max-width="500px"
                          >
                            <v-card>
                              <v-card-title class="text-h5"
                                >Sei sicuro di voler cancellare l'elemento
                                selezionato?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeDeleteManodopera"
                                  >Annulla</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirmManodopera"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            v-show="!shouldDisable"
                            small
                            @click="deleteItemManodopera(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-divider class="mx-4"></v-divider>
                  <v-row>
                    <v-col>
                      <v-card-title
                        class="black--text"
                        style="margin-bottom: 20px; margin-top: 20px"
                      >
                        {{ $t("datiManodopera") }}
                      </v-card-title>
                    </v-col>
                    <v-col style="margin-top: 10px" align="end">
                      <v-btn
                        tile
                        color="darkred"
                        @click="onNuovaManodoperaClick"
                        :disabled="shouldDisable"
                      >
                        <v-icon left> mdi-plus-circle-outline </v-icon>
                        {{ $t("nuovaManodopera") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="t_destinazioneuso"
                        :label="$t('veicolo.destinazioneUso')"
                        item-text="cDescrizione"
                        item-value="id"
                        v-model="manodoperaSel.tipoVeicoloDestUso"
                        return-object
                        :disabled="shouldDisable"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="manodoperaSel.nAnno"
                        :label="$t('anno')"
                        type="number"
                        min="1970"
                        max="2050"
                        :rules="[rules.valueMin(manodoperaSel.nAnno, 1970), rules.valueMax(manodoperaSel.nAnno, 2050)]"
                        :disabled="shouldDisable"
                        @keydown="onKeyDownDecimalAndNegative"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-currency-field 
                        :label="$t('prezzoUnitario')"
                        prefix="€"
                        v-model="manodoperaSel.nPrezzoUnitario"
                        :disabled="shouldDisable"
                        />
                    </v-col>
                  </v-row>

                  <v-divider class="mx-4"></v-divider>

                  <v-row>
                    <v-col style="margin-top: 10px" align="end">
                      <v-btn
                        tile
                        color="darkred"
                        @click="onSalvaManodoperaClick"
                        :disabled="shouldDisable"
                      >
                        <v-icon left> mdi-content-save </v-icon>
                        {{ $t("fornitori.salvaManodopera") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-divider class="mx-4"></v-divider>
            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-alert
                id="alertDettaglio"
                shaped
                :value="isAlertDetailVisible"
                :type="alertType"
                dismissible
              >
                {{ messaggioAlert }}
              </v-alert>
            </v-row>

            <v-row
              style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
            >
              <v-btn
                v-show="!isFornDeterminaStorico"
                tile
                color="darkred"
                v-if="!isHidden"
                v-on:click="isHidden = true"
                @click="onEditClick"
              >
                <v-icon left> mdi-pencil </v-icon>
                {{ $t("modifica") }}
              </v-btn>
              <v-btn
                v-show="!isFornDeterminaStorico"
                tile
                color="darkred"
                v-if="isHidden"
                @click="onSaveClick"
              >
                <v-icon left> mdi-content-save </v-icon>
                {{ $t("salva") }}
              </v-btn>
              <v-btn
                v-show="!isFornDeterminaStorico"
                tile
                color="darkred"
                v-if="isDisattivaEnabled"
                @click="onDisattivaClick"
              >
                <v-icon left> mdi-delete </v-icon>
                {{ $t("disattiva") }}
              </v-btn>
              <v-btn tile color="darkred" @click="closeDetail">
                <v-icon left> mdi-door-open </v-icon>
                {{ $t("chiudi") }}
              </v-btn>
              <v-dialog
                v-model="dialogDisattivaFornDetermina"
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h5"
                    >Sei sicuro di voler disattivare la determina selezionata?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDisattivaFornDetermina"
                      >Annulla</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDisattivaFornDetermina"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";

import Utils from "@/utils/Utils";
import UtilsDate from "@/utils/UtilsDate";

import FornitoriService from "@/service/FornitoriService";
import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";
import ExcelService from "@/service/ExcelService";
import AuthService from '@/service/AuthService';

import CompDocuments from "@/components/CompDocuments";

@Component({
  components: {
    CompDocuments,
  },
})
export default class Fornitori extends Vue {
  fornitori = {
    select: [],
    selected: null,
    source: [],
  };

  rules = Utils.rules;

  fornitoriTabs = [
    "Anagrafica Fornitore",
    "Determina",
    "Documenti",
    "Budget",
    "Manodopera",
  ];

  headersFornitoriMainColumns = [
    {
      text: "Ragione Sociale",
      align: "start",
      value: "fornitore.cRagioneSociale",
    },
    { text: "Tipo Fornitore", value: "fornitore.tipoFornitore" },
    { text: "Ente", value: "enteDTO.cSigla" },
    { text: "Determina", value: "determinaAnno" },
    { text: "Impegno", value: "impegnoAnno" },
    { text: "Budget Totale Anno", value: "budgetAnnuale" },
    { text: "Budget Utilizzato", value: "budgetUtilizzato" },
    { text: "Budget Residuo", value: "budgetResiduo" },
    { text: "Spesa media mensile", value: "budgetSpesaMediaMensile" },
    { text: "Scostamento", value: "budgetScostamento" },
  ];

  headersColumnsRidotte = [
    {
      text: "Ragione Sociale",
      align: "start",
      value: "fornitore.cRagioneSociale",
    },
    { text: "Tipo Fornitore", value: "fornitore.tipoFornitore" },
    { text: "Ente", value: "enteDTO.cDescrizione" },
    { text: "Determina", value: "determinaAnno" },
  ];
  /*
  headersDocumenti = [
    {
      text: "Tipo documento",
      align: "start",
      value: "tDocumento.cDescrizione",
    },
    { text: "Nome documento", value: "cNomeFile" },
    { text: "Data caricamento", value: "hIns" },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDelDocumentFilter,
    },
    { text: "Azioni", value: "actions", sortable: false },
  ];
*/
  headersImpegni = [
    {
      text: "Numero",
      align: "start",
      value: "nNumero",
    },
    { text: "Anno", value: "nAnno" },
    { text: "Importo totale", value: "nTotale" },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "Azioni", value: "actions", sortable: false },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDelImpegnoFilter,
    },
  ];

  headersManodopera = [
    {
      text: "Destinazione Uso",
      align: "start",
      value: "tipoVeicoloDestUso.cDescrizione",
    },
    { text: "Anno", value: "nAnno" },
    { text: "Prezzo Unitario", value: "nPrezzoUnitario" },
    { text: "Azioni", value: "actions", sortable: false },
    {
      text: "Data cancellazione",
      value: "hDel",
      align: " d-none",
      filter: this.hDelManodoperaFilter,
    },
  ];

  t_tipofornitore = [
    {
      id: 1,
      cDescrizione: "Veicoli",
    },
    {
      id: 2,
      cDescrizione: "Attrezzature",
    },
  ];

  t_anni = [];
  t_anniOrig = [];

  t_documenti = [];
  t_documentiOrig = [];

  t_categoriaforn = [];
  t_categoriafornOrig = [];

  t_destinazioneuso = [];
  t_destinazioneusoOrig = [];

  eFornitori = [];

  eEnti = [];

  eCapitoliSpesa = [];

  //eImpegni = [];

  //eManodopere = [];

  // Variabili globaili
  tab = null;
  dateFormatEng = "YYYY-MM-DD";
  fornitoreSel = null;
  fornitoreTemplate = null;
  guidImpegnoToDelete = null;
  guidManodoperaToDelete = null;
  dialogDeleteImpegno = false;
  dialogDeleteManodopera = false;
  isEditFornitore = false;
  isNuovoContratto = false;
  isloading_fornitori = false;
  isAlertDetailVisible = false;
  isAlertGridVisible = false;
  messaggioAlert = "";
  alertType = "error";
  search = "";
  dimension = 0;
  shouldDisable = true;
  enteShouldDisable = true;
  saveClicked = false;
  isHidden = false;
  defaultAnno = 0;
  defaultFornitore = "Veicoli";
  impegnoTempl = null;
  impegnoSel = null;
  manodoperaTempl = null;
  manodoperaSel = null;
  radios = "Nuovo";
  t_fornSel = null;
  dialogDisattivaFornDetermina = false;
  isFornDeterminaStorico = false;
  isCreatedCorrectly = false;
  isDisattivaEnabled = false;

  // ***********************************************************
  // FUNZIONI E PROCEDURE DI SISTEMA
  // ***********************************************************

  mounted() {
    if (this.isCreatedCorrectly)
      this.onContainerChange();
  }

  created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "R_FORNITORI");
    switch (verLoadingPage)
    {
      case 0: // All OK
        this.isCreatedCorrectly = true
        // Change with dynamic value
        var enteId = this.$store.state.enteSel.id;
        if (this.$store.state.roleSel.CCode == "ADM") 
          this.isDisattivaEnabled = true;
        else
          this.isDisattivaEnabled = false;
        var storico = false;
        // Caricamento oggetto principale
        this.loadAnni(enteId, storico);

        // Caricamento altri oggetti principali
        this.loadElencoFornitori();
        if (enteId > 0)
          this.loadEnteAndLinkObjects(enteId);
        // Caricamento Tipologiche
        this.loadAllTipologiche();
        // Restituzione dei template
        this.getTemplateNuovoFornitore();
        this.getTemplateImpegno();
        this.getTemplateManodopera();
        break;
      
      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }

  }

  onTableRowClick(fornitore, row) {
    this.fornitoreSel = fornitore;
    this.impegnoSel = Object.assign({}, this.impegnoTempl);
    this.manodoperaSel = Object.assign({}, this.manodoperaTempl);

    if (!this.isEditFornitore)
      this.tab = "Anagrafica Fornitore";
    this.isEditFornitore = true;
    this.isNuovoContratto = false;
    //row.select(true);

    // Carico le tipologiche dell'elemento selezionato
    this.fillTipologicheERelazioni();

    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;

    if (this.fornitoreSel.hDel != null) this.isFornDeterminaStorico = true;
    else this.isFornDeterminaStorico = false;
  }

  onTableRowImpegniClick(impegno, row) {
    if (impegno.id != 0)
      this.impegnoSel = impegno;
    else
      this.impegnoSel = this.impegnoTempl;
  }

  onTableRowManodopereClick(manodopera, row) {
    this.manodoperaSel = manodopera;
  }

  onDisattivaClick() {
    this.dialogDisattivaFornDetermina = true;
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblMainFornitori").offsetWidth;
      return self.dimension;
    });
  }

  onNuovoFornitoreClick() {

    // Chiudo l'oggetto precedente prima di aprire il nuovo
    this.$refs.form.resetValidation();
    this.saveClicked = false;
    //this.eCapitoliSpesa = [];

    // Caricamento oggetti principali in relazione
    this.loadEnti();

    this.impegnoSel = Object.assign({}, this.impegnoTempl);
    this.manodoperaSel = Object.assign({}, this.manodoperaTempl);
    
    // Tipologiche
    this.loadAllTipologicheAttive(true);

    // Impostazione variabili
    this.isEditFornitore = true;
    this.isNuovoContratto = true;
    this.shouldDisable = false;
    this.isHidden = true;

    this.onContainerChange();
    this.radios = "Nuovo";
    //
    this.tab = "Anagrafica Fornitore";
    
    this.getTemplateNuovoFornitore();
    this.fornitoreSel = Object.assign({}, this.fornitoreTemplate);

    this.fornitoreSel.fornitore.cRagioneSociale = "";
  }

  onEditClick() {
    // Caricamento oggetti principali in relazione

    // Caricamento Tipologiche
    this.loadAllTipologicheAttive(true);

    // Carico da db l'oggetto Fornitore selezionato
    this.loadFornitoreImpegno(this.fornitoreSel.idImpegno, this.onContainerChange);

    this.shouldDisable = false;
  }

  onSaveClick() {
    // Verifico se i campi della form sono tutti validi.
    // Per funzionare nel template ci deve essere un oggetto di tipo V-FORM
    debugger;
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
      return;
    } else {
      if (this.fornitoreSel.impegni.length <= 0) {
        this.alertType = "error";
        // In caso di errore del salvataggio continuare con l'editing
        this.messaggioAlert = i18n.t("erroreInserimentoImpegno");
        this.showHide_alertDetail(8000);
        return;
      }
      else
      {
        for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
          var item = this.fornitoreSel.impegni[i];
          if (Utils.isNullOrUndefinedOrEmpty(item.nNumero)) {
            item.nNumero = 0;
          }
        }
      }
      // Verifica sulle date
      if (UtilsDate.isGreaterThen(this.fornitoreSel.dataInizioText, this.fornitoreSel.dataFineText))
      {
        this.alertType = "error";
        this.messaggioAlert = i18n.t("erroreDataInizioFine");
        this.showHide_alertDetail(8000);
        return;        
      }

      if (this.fornitoreSel.id > 0)
        // Salvo il dato sul DB
        this.saveFornitoreDto(this.fornitoreSel.id);
      // Creo il nuovo record
      else this.createFornitoreDto();
    }
  }

  onNuovoImpegnoClick() {
    this.impegnoSel = Object.assign({}, this.impegnoTempl);
    this.impegnoSel.nImpIniziale = 0;
    this.impegnoSel.nAssestamento1 = 0;
    this.impegnoSel.nAssestamento2 = 0;
    this.impegnoSel.nAssestamento3 = 0;
    this.impegnoSel.nAssestamento4 = 0;
    this.$refs.form.resetValidation();
  }

  onDuplicaImpegnoClick() {
    this.impegnoSel = Object.assign({}, this.impegnoSel);
    this.impegnoSel.nNumero = 0;
    this.impegnoSel.nImpIniziale = 0;
    this.impegnoSel.nAssestamento1 = 0;
    this.impegnoSel.nAssestamento2 = 0;
    this.impegnoSel.nAssestamento3 = 0;
    this.impegnoSel.nAssestamento4 = 0;
    this.impegnoSel.id = 0;
  }
  
  onSalvaImpegnoClick() {
    if (
      Utils.isNullOrUndefined(this.impegnoSel.nAnno) ||
      Utils.isNullOrUndefined(this.impegnoSel.nImpIniziale) ||
      //Utils.isNullOrUndefined(this.impegnoSel.nNumero) ||
      this.impegnoSel.nAnno == "" ||
      this.impegnoSel.nImpIniziale == ""
      //this.impegnoSel.nNumero == ""
    ) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreAggiuntaImpegno");
      this.showHide_alertDetail(8000);
      return;
    }
    if (
      //parseInt(this.impegnoSel.nNumero) <= 0 ||
      parseInt(this.impegnoSel.nImpIniziale) <= 0 ||
      parseInt(this.impegnoSel.nAnno) <= 1990
    ) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreValoriImpegno");
      this.showHide_alertDetail(8000);
      return;
    }
    // Verifico che l'anno sia compreso nel range della data della determina
    var annoInit = UtilsDate.getYearFromDate(this.fornitoreSel.dataInizioText);
    var annoFine = UtilsDate.getYearFromDate(this.fornitoreSel.dataFineText);
    if (Utils.isNullOrUndefined(annoInit) || Utils.isNullOrUndefined(annoFine))
    {
      // Entrambe le date devono essere inserite
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreImpegnoRangeMancante");
      this.showHide_alertDetail(8000);
      return;
    }
    else
    {
      if (parseInt(this.impegnoSel.nAnno) < parseInt(annoInit) || 
          parseInt(this.impegnoSel.nAnno) > parseInt(annoFine))
        {
          // Non contenuto nel range
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreImpegnoRange");
          this.showHide_alertDetail(8000);
          return;
        }
    }

    if (this.impegnoSel.id == 0) {
      // Aggiungo l'impegno in griglia
      this.impegnoSel.guid = Utils.createGuid();
      if (this.fornitoreSel.id > 0)
        this.impegnoSel.eFornDetermineId = this.fornitoreSel.id;
      this.fornitoreSel.impegni.push(this.impegnoSel);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
        var item = this.fornitoreSel.impegni[i];
        if (item.guid == this.impegnoSel.guid) {
          item = this.impegnoSel;
        }
      }
    }
    // Svuoto i campi dopo l'inserimento in griglia
    this.onNuovoImpegnoClick();
  }

  onNuovaManodoperaClick() {
    this.manodoperaSel = Object.assign({}, this.manodoperaTempl);
    this.manodoperaSel.nPrezzoUnitario = 0;
  }

  onSalvaManodoperaClick() {
    // Verificare che tutti i campi siano inputati
    if (
      Utils.isNullOrUndefined(this.manodoperaSel.nAnno) ||
      Utils.isNullOrUndefined(this.manodoperaSel.nPrezzoUnitario) ||
      this.manodoperaSel.tipoVeicoloDestUso.id <= 0 ||
      this.manodoperaSel.nAnno == "" ||
      this.manodoperaSel.nPrezzoUnitario == ""
    ) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreAggiuntaManodopera");
      this.showHide_alertDetail(8000);
      return;
    }
    // Verifico la validità dei valori immessi
    if (
      parseInt(this.manodoperaSel.nPrezzoUnitario) <= 0 ||
      parseInt(this.manodoperaSel.nAnno) <= 1990
    ) {
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreValoriManodopera");
      this.showHide_alertDetail(8000);
      return;
    }

    // Verifico che l'anno sia compreso nel range della data della determina
    var annoInit = UtilsDate.getYearFromDate(this.fornitoreSel.dataInizioText);
    var annoFine = UtilsDate.getYearFromDate(this.fornitoreSel.dataFineText);
    if (Utils.isNullOrUndefined(annoInit) || Utils.isNullOrUndefined(annoFine))
    {
      // Entrambe le date devono essere inserite
      this.alertType = "error";
      this.messaggioAlert = i18n.t("erroreManodoperaRangeMancante");
      this.showHide_alertDetail(8000);
      return;
    }
    else
    {
      if (parseInt(this.manodoperaSel.nAnno) < parseInt(annoInit) || 
          parseInt(this.manodoperaSel.nAnno) > parseInt(annoFine))
        {
          // Non contenuto nel range
          this.alertType = "error";
          this.messaggioAlert = i18n.t("erroreManodoperaRange");
          this.showHide_alertDetail(8000);
          return;
        }
    }

    if (this.manodoperaSel.id == 0) {
      // Aggiungo l'oggetto in griglia
      this.manodoperaSel.guid = Utils.createGuid();
      if (this.fornitoreSel.id > 0)
        this.manodoperaSel.eFornDetermineId = this.fornitoreSel.id;
      this.fornitoreSel.manodopere.push(this.manodoperaSel);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.fornitoreSel.manodopere.length; i++) {
        var item = this.fornitoreSel.manodopere[i];
        if (item.guid == manodoperaSel.guid) {
          item = manodoperaSel;
        }
      }
    }
    // Svuoto i campi dopo l'inserimento in griglia
    this.onNuovaManodoperaClick();
  }

  onRadioChanged() {
    if (this.radios == "Esistente") {
      if (this.t_fornSel == null) this.t_fornSel = this.eFornitori[0];
      this.fornitoreSel.fornitore = this.t_fornSel;
    } else {
      this.fornitoreSel.fornitore = Object.assign({}, this.fornitoreTemplate.fornitore);
      this.$refs.form.resetValidation();
    }
  }

  onFornitoreChange() {
    if (this.t_fornSel == null) this.t_fornSel = this.eFornitori[0];
    this.fornitoreSel.fornitore = this.t_fornSel;
  }

  onAnnoChange() {
    var enteId = this.$store.state.enteSel.id;
    this.loadFornitoriImpegni(this.defaultAnno, enteId);
    this.closeDetail();
  }

	onKeyDownDecimal(key) {
		if (key.key == "." || key.key == ",")
		{
		  key.preventDefault();
		}
		else
		  return true;
  }

	onKeyDownDecimalAndNegative(key) {
		if (key.key == "." || key.key == "," || key.key == "-")
		{
			key.preventDefault();
		}
		else
			return true;
  }

	onKeyDownNegative(key) {
		if (key.key == "-")
		{
		  key.preventDefault();
		}
		else
		  return true;
  }

  confirmDisattivaFornDetermina() {
    this.disattivaFornDeterminaDto(this.fornitoreSel.id);
    this.dialogDisattivaFornDetermina = false;
  }

  closeDisattivaFornDetermina() {
    this.dialogDisattivaFornDetermina = false;
  }

  closeDetail() {
    this.isEditFornitore = false;
    this.isNuovoContratto = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;
  }

  getTabSelected(tabId) {
    if (this.saveClicked) this.$refs.form.validate();
  }

  // ***********************************************************
  // FUNZIONI E PROCEDURE DI GESTIONE PAGINA
  // ***********************************************************

  changeTotale() {
    let iniz = Utils.isNullOrUndefined(this.impegnoSel.nImpIniziale)
      ? 0
      : this.impegnoSel.nImpIniziale;
    let ass1 = Utils.isNullOrUndefined(this.impegnoSel.nAssestamento1)
      ? 0
      : this.impegnoSel.nAssestamento1;
    let ass2 = Utils.isNullOrUndefined(this.impegnoSel.nAssestamento2)
      ? 0
      : this.impegnoSel.nAssestamento2;
    let ass3 = Utils.isNullOrUndefined(this.impegnoSel.nAssestamento3)
      ? 0
      : this.impegnoSel.nAssestamento3;
    let ass4 = Utils.isNullOrUndefined(this.impegnoSel.nAssestamento4)
      ? 0
      : this.impegnoSel.nAssestamento4;
    this.impegnoSel.nTotale =
      parseFloat(iniz) +
      parseFloat(ass1) +
      parseFloat(ass2) +
      parseFloat(ass3) +
      parseFloat(ass4);
  }

  showAlertMessage(alertType, localVariableName) {
    this.alertType = alertType;
    this.messaggioAlert = i18n.t(localVariableName);
    this.showHide_alertDetail(8000);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      //console.log("hide alert after " + millisecondInterval / 1000 + " seconds");
    }, millisecondInterval);
  }



  /*
  hDelDocumentFilter(value) {
    // Necessario per filtrare la griglia dei documenti e non visualizzare i doc cancellati
    return value === null;
  }
*/
  hDelImpegnoFilter(value) {
    // Necessario per filtrare la griglia degli impegni e non visualizzare quelli cancellati
    return value === null;
  }

  hDelManodoperaFilter(value) {
    // Necessario per filtrare la griglia delle manodopere e non visualizzare quelli cancellati
    return value === null;
  }

  fillTipologicheERelazioni() {
    // Tipologiche
    this.loadAllTipologicheAttive(false);
    //this.t_categoriaforn = [this.fornitoreSel.fornitore.fornCategoria];

    // Relazioni
    // Creazione Guid oggetti in relazione

    for (let i = 0; i < this.fornitoreSel.documenti.length; i++) {
      var item = this.fornitoreSel.documenti[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }

    for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
      var item = this.fornitoreSel.impegni[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }

    for (let i = 0; i < this.fornitoreSel.manodopere.length; i++) {
      var item = this.fornitoreSel.manodopere[i];
      item.guid = Utils.createGuid();
      item.hIns = UtilsDate.convertStringToDate(item.hIns);
    }

    //this.eCapitoliSpesa = [this.fornitoreSel.dotazioneAttuale.capitoloSpesaDTO];
    this.eEnti = [this.fornitoreSel.enteDTO];
  }

  get headersColumns() {
    let tblWidth = this.dimension;
    //console.log(tblWidth);
    if (tblWidth < 1000) return this.headersColumnsRidotte;
    else return this.headersFornitoriMainColumns;
  }

  loadDocument(docTemp) {
    if (docTemp.id == 0) {
      // Aggiungo l'oggetto in griglia
      docTemp.guid = Utils.createGuid();
      if (this.fornitoreSel.id > 0) docTemp.objectId = this.fornitoreSel.id;
      this.fornitoreSel.documenti.push(docTemp);
    } else {
      // Aggiorno l'oggetto in griglia
      for (let i = 0; i < this.fornitoreSel.documenti.length; i++) {
        var item = this.fornitoreSel.documenti[i];
        if (item.guid == docTemp.guid) {
          item = docTemp;
        }
      }
    }
  }

  deleteDocument(guid) {
    for (let i = 0; i < this.fornitoreSel.documenti.length; i++) {
      var item = this.fornitoreSel.documenti[i];
      if (item.guid == guid) {
        item.hDel = new Date();
        break;
      }
    }
  }

  deleteItemImpegno(item) {
    this.guidImpegnoToDelete = item.guid;
    this.dialogDeleteImpegno = true;
  }

  closeDeleteImpegno() {
    this.dialogDeleteImpegno = false;
  }

  deleteItemConfirmImpegno() {
    for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
      var item = this.fornitoreSel.impegni[i];
      if (item.guid == this.guidImpegnoToDelete) {
        {
          if (item.id == 0)
            this.fornitoreSel.impegni.splice(i, 1);
          else
            item.hDel = new Date();
          
          break;
        }
      }
    }

    this.dialogDeleteImpegno = false;
  }

  deleteItemManodopera(item) {
    this.guidManodoperaToDelete = item.guid;
    this.dialogDeleteManodopera = true;
  }

  closeDeleteManodopera() {
    this.dialogDeleteManodopera = false;
  }

  deleteItemConfirmManodopera() {
    for (let i = 0; i < this.fornitoreSel.manodopere.length; i++) {
      var item = this.fornitoreSel.manodopere[i];
      if (item.guid == this.guidManodoperaToDelete) {
        item.hDel = new Date();
        break;
      }
    }

    this.dialogDeleteManodopera = false;
  }

  // ***********************************************************
  // DATABASE
  // ***********************************************************

  loadEnti() {
    var self = this;

    EntiService.getEnti(
      (resp) => {
        console.log("GetEnti");
        console.log(resp);

        self.eEnti = resp;
        
        // Gestione Utente / Profilazione
        if (self.$store.state.roleSel != null)
        {
          if (self.$store.state.roleSel.CCode == "ADM")
          {
            if (self.$store.state.enteSel.id == 0)
            {
                // Può selezionare
                // TODO
              // Disabilito le select degli enti
              self.enteShouldDisable = false;
            }
            else
            {
              // E' bloccato come un utente qualsiasi
              // Setto l'ente selezionato in Dashboard
              if (!Utils.isNullOrUndefined(self.fornitoreSel))
              {
                self.fornitoreSel.enteDTO = self.$store.state.enteSel;
                // Disabilito le select degli enti
                self.enteShouldDisable = true;
              }
            }
          }
          else
          {
            if (!Utils.isNullOrUndefined(self.fornitoreSel))
            {
              self.fornitoreSel.enteDTO = self.$store.state.enteSel;
              
              // Disabilito le select degli enti
              self.enteShouldDisable = true;
            }
            // Disabilito le select degli enti
            self.enteShouldDisable = true;
          }
        }

      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
      }
    );
  }

  loadEnteAndLinkObjects(idEnte) {
    var self = this;

    EntiService.getEnteAndLinkObjects(
      idEnte,
      (resp) => {
        console.log("GetEnteAndLinkObjects");
        console.log(resp);
        self.eCapitoliSpesa = resp.capitoliSpesaEnte;
      },
      (err) => {
        console.log("Errore GetEnteAndLinkObjects");
        console.log(err);
      }
    );
  }
/*
  loadFornitori(anno, enteId) {
    var self = this;
    self.isloading_fornitori = true;

    //var header = { 'headers': { 'Authorization': 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiUmVzcDEiLCJkZXNjcmlwdGlvbiI6Ik5DSSBKV1QgQXV0aGVudGljYXRpb24gU2VydmljZSIsIklkIjoiMSIsImlhdCI6IjE2NDQ0MTQ1MjgiLCJqdGkiOiI1M2ZhZTMxNy0wMmQ4LTQyMDctYTU5My1jOWVmYzBmMGE5NWEiLCJuYmYiOjE2NDQ0MTQ1MjgsImV4cCI6MTY0NDQxNDgyOCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwIn0.RYHh29g3aQI2JMcDwLtrPZfopKfswLyTw0W77ecb9Nc' } };
    FornitoriService.getFornDetermine(
      anno,
      enteId,
      (resp) => {
        self.isloading_fornitori = false;
        console.log("Fornitori:");
        console.log(resp);

        self.fornitori.source = resp;
      },
      (err) => {
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoFornitori");
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }
*/
  loadFornitoriImpegni(anno, enteId) {
    var self = this;
    self.isloading_fornitori = true;

    //var header = { 'headers': { 'Authorization': 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiUmVzcDEiLCJkZXNjcmlwdGlvbiI6Ik5DSSBKV1QgQXV0aGVudGljYXRpb24gU2VydmljZSIsIklkIjoiMSIsImlhdCI6IjE2NDQ0MTQ1MjgiLCJqdGkiOiI1M2ZhZTMxNy0wMmQ4LTQyMDctYTU5My1jOWVmYzBmMGE5NWEiLCJuYmYiOjE2NDQ0MTQ1MjgsImV4cCI6MTY0NDQxNDgyOCwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwIn0.RYHh29g3aQI2JMcDwLtrPZfopKfswLyTw0W77ecb9Nc' } };
    FornitoriService.getFornDetermineImpegni(
      anno,
      enteId,
      (resp) => {
        self.isloading_fornitori = false;
        console.log("Fornitori Impegni:");
        console.log(resp);

        self.fornitori.source = resp;
      },
      (err) => {
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoFornitori");
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }

  loadFornitore(id, onSuccess) {
    var self = this;

    FornitoriService.getFornDetermina(
      id,
      (resp) => {
        console.log("Fornitore:");
        console.log(resp);

        self.fornitoreSel = resp;

        for (let i = 0; i < this.fornitoreSel.documenti.length; i++) {
          var item = this.fornitoreSel.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
          var item = this.fornitoreSel.impegni[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < this.fornitoreSel.manodopere.length; i++) {
          var item = this.fornitoreSel.manodopere[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }

  loadFornitoreImpegno(id, onSuccess) {
    var self = this;

    FornitoriService.getFornDeterminaImpegno(
      id,
      (resp) => {
        console.log("Fornitore:");
        console.log(resp);

        self.fornitoreSel = resp;

        for (let i = 0; i < this.fornitoreSel.documenti.length; i++) {
          var item = this.fornitoreSel.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < this.fornitoreSel.impegni.length; i++) {
          var item = this.fornitoreSel.impegni[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < this.fornitoreSel.manodopere.length; i++) {
          var item = this.fornitoreSel.manodopere[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        if (typeof onSuccess === "function") onSuccess(resp);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }

  getTemplateNuovoFornitore() {
    var self = this;
    //var url = environment.urlSrc + "FornDeterm/GetTemplateFornDetermina";

    FornitoriService.getTemplateFornDetermina(
      (resp) => {
        console.log("getTemplateFornDetermina:");
        console.log(resp);
        self.fornitoreTemplate = resp;
        // Inizializzazione variabili non settate
        self.fornitoreTemplate.id = 0;
        self.fornitoreTemplate.cSigla = "";
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(5000);
      }
    );
  }

  getTemplateImpegno() {
    var self = this;

    FornitoriService.getTemplateImpegno(
      (resp) => {
        console.log("getTemplateImpegno");
        console.log(resp);

        self.impegnoTempl = resp;
        self.impegnoTempl.nNumero = '';
        self.impegnoTempl.nImpIniziale = '';
        self.impegnoTempl.nAnno = '';
      },
      (err) => {
        console.log("Errore getTemplateImpegno");
        console.log(err);
        return null;
      }
    );
  }

  getTemplateManodopera() {
    var self = this;

    FornitoriService.getTemplateManodopera(
      (resp) => {
        console.log("getTemplateManodopera");
        console.log(resp);

        self.manodoperaTempl = resp;
        self.manodoperaTempl.nPrezzoUnitario = '';
        self.manodoperaTempl.nAnno = '';
      },
      (err) => {
        console.log("Errore getTemplateManodopera");
        console.log(err);
        return null;
      }
    );
  }

  loadAnni(enteId) {
    var self = this;
    self.isloading_fornitori = true;

    FornitoriService.getAnniFornDetermine(
      enteId,
      (resp) => {
        self.isloading_fornitori = false;
        console.log("GetAnniFornDetermine:");
        console.log(resp);
        self.t_anni = resp;

        if (self.t_anni.length > 0) {
          if (self.t_anni.indexOf(new Date().getFullYear()) > -1)
            self.defaultAnno = new Date().getFullYear();
          else self.defaultAnno = self.t_anni[0];
          self.loadFornitoriImpegni(self.defaultAnno, enteId);
        } else {
          self.loadFornitoriImpegni(new Date().getFullYear(), enteId);
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoFornitori");
        self.showHide_alertGrid(5000);
        self.isloading_fornitori = false;
      }
    );
  }

  loadElencoFornitori() {
    var self = this;

    FornitoriService.getFornitori(
      (resp) => {
        console.log("GetFornitori");
        console.log(resp);

        self.eFornitori = resp;
      },
      (err) => {
        console.log("Errore GetFornitori");
        console.log(err);
      }
    );
  }

  createFornitoreDto() {
    var self = this;
    var enteId = this.$store.state.enteSel.id;

    FornitoriService.createFornDetermina(
      self.fornitoreSel,
      (resp) => {
        console.log("CreateFornDetermina");
        console.log(resp);
        if (resp.result == 1) {
          // Buon fine
          // Messaggio di buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertGrid(5000);
          // Ricarico tutta la griglia e chiudo il dettaglio
          self.closeDetail();
          self.onAnnoChange();
          //self.loadAnni(enteId);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  saveFornitoreDto(id) {
    var self = this;
    FornitoriService.updateFornDetermina(
      self.fornitoreSel,
      (resp) => {
        console.log("UpdateFornDetermina");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia
          self.updateGridFornitore(self.fornitoreSel.idImpegno);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  disattivaFornDeterminaDto(id) {
    var self = this;

    FornitoriService.deleteFornDetermina(
      self.fornitoreSel.id,
      (resp) => {
        console.log("deleteFornDetermina");
        console.log(resp);

        if (resp.result == 1) {
          // Buon fine
          this.alertType = "success";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertGrid(5000);
          // Azzero l'oggetto
          this.fornitoreSel = null;
          // Ricarico la griglia
          this.onAnnoChange();
          this.closeDetail();
        } else {
          // KO
          this.alertType = "error";
          this.messaggioAlert = resp.userMsg.message;
          this.showHide_alertDetail(8000);
        }

        this.isHidden = !this.isHidden;
        this.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  updateGridFornitore(idImpegno, onSuccess) {
    var self = this;

  FornitoriService.getFornDeterminaImpegno(
      idImpegno,
      (resp) => {
        console.log("Fornitore:");
        console.log(resp);

        var fornitoreTmp = resp;
        for (let i = 0; i < fornitoreTmp.documenti.length; i++) {
          var item = fornitoreTmp.documenti[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }
        for (let i = 0; i < fornitoreTmp.impegni.length; i++) {
          var item = fornitoreTmp.impegni[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        for (let i = 0; i < fornitoreTmp.manodopere.length; i++) {
          var item = fornitoreTmp.manodopere[i];
          item.guid = Utils.createGuid();
          item.hIns = UtilsDate.convertStringToDate(item.hIns);
        }

        if (typeof onSuccess === "function") onSuccess(resp);

        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.fornitori.source.length; i++) {
          var item = self.fornitori.source[i];
          if (item.idImpegno == idImpegno) {
            found = true;
            self.fornitori.source.splice(i, 1);
            self.fornitori.source.push(fornitoreTmp);
            self.fornitori.selected = fornitoreTmp;
            break;
          }
        }
        if (!found)
        {
          // Caso di nuovo oggetto
          self.fornitori.source.push(fornitoreTmp);
          self.fornitori.selected = fornitoreTmp;
        }        
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
        self.isloading_fornitori = false;
      }
    );

  }

  // **************************
  // TIPOLOGICHE
  // **************************

  loadAllTipologiche() {
    // Caricamento di tutte le Tipologiche
    // Fornitori
    this.loadTVeicoloCategoria();
    this.loadTVeicoloDestUso();

    // Documenti
    this.loadTDocumenti();
  }

  loadAllTipologicheAttive(soloAttive) {
    // Caricamento di tutte le Tipologiche
    if (soloAttive)
    {
      // SOLO le tipologiche attive

      this.t_categoriaforn = Utils.getTipologicheAttive(this.t_categoriafornOrig);
      this.t_destinazioneuso = Utils.getTipologicheAttive(this.t_destinazioneusoOrig);
      this.t_documenti = Utils.getTipologicheAttive(this.t_documentiOrig);
    }
    else
    {
      // TUTTE le tipologiche

      this.t_categoriaforn = this.t_categoriafornOrig;
      this.t_destinazioneuso = this.t_destinazioneusoOrig;
      this.t_documenti = this.t_documentiOrig;
    }
  }

  loadTVeicoloCategoria() {
    var self = this;

    TipologicheService.getTFornCategoria(
      (resp) => {
        console.log("GetTFornCategoria");
        console.log(resp);

        self.t_categoriaforn = resp;
        self.t_categoriafornOrig = Utils.cloneArray(self.t_categoriaforn);
      },
      (err) => {
        console.log("Errore GetTFornCategoria");
        console.log(err);
      }
    );
  }

  loadTVeicoloDestUso() {
    var self = this;

    TipologicheService.getTVeicoloDestUso(
      (resp) => {
        console.log("GetTVeicoloDestUso");
        console.log(resp);

        self.t_destinazioneuso = resp;
        self.t_destinazioneusoOrig = Utils.cloneArray(self.t_destinazioneuso);
      },
      (err) => {
        console.log("Errore GetTVeicoloDestUso");
        console.log(err);
      }
    );
  }

  loadTDocumenti() {
    var self = this;

    TipologicheService.getTDocumentoFornDetermineAll(
      (resp) => {
        console.log("GetTDocumentoFornDetermineAll");
        console.log(resp);

        self.t_documenti = resp;
        self.t_documentiOrig = Utils.cloneArray(self.t_documenti);
      },
      (err) => {
        console.log("Errore GetTDocumentoFornDetermineAll");
        console.log(err);
      }
    );
  }

  onExportGrid() {
    var enteId = this.$store.state.enteSel.id;
    var anno = this.defaultAnno;
    
    ExcelService.generaExcelFornitori(
      anno,
      enteId,
      function(data, resp) {
          const blob = new Blob([data]);
          const el = document.createElement("a");
          el.href = window.URL.createObjectURL(blob);
          const regex = /filename=(?<filename>[^,;]+);/g;
          const match = regex.exec(resp.headers['content-disposition']);
          let filename = ""; // or any other value you consider default
          if (typeof match !== 'undefined' && match !== null) {
              filename = match.groups['filename'];
          }
          el.download = filename;
          el.click();
        },
        (fail) => {
          alert(fail);
        },
    )
  }
}
</script>